import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { InfoIcon, List, Table, Row, Tabs, Expander, Attributes, WarningIcon } from '../components';
import { requestNonceRaw, requestNonceCurl, requestNonceJava, requestNonceJavascript, requestNonceNode, requestNoncePerl, requestNoncePython, requestNoncePhp, requestNonceRuby, requestNonceGo, requestNonceC, requestNonceVB, requestNonceGroovy, requestNonceObjectiveC, requestNonceSwift, requestClientTokenRaw, requestClientTokenCurl, requestClientTokenJava, requestClientTokenJavascript, requestClientTokenNode, requestClientTokenPerl, requestClientTokenPython, requestClientTokenPhp, requestClientTokenRuby, requestClientTokenGo, requestClientTokenC, requestClientTokenVB, requestClientTokenGroovy, requestClientTokenObjectiveC, requestClientTokenSwift, requestDelegationTokenRaw, requestDelegationTokenCurl, requestDelegationTokenJava, requestDelegationTokenJavascript, requestDelegationTokenNode, requestDelegationTokenPerl, requestDelegationTokenPython, requestDelegationTokenPhp, requestDelegationTokenRuby, requestDelegationTokenGo, requestDelegationTokenC, requestDelegationTokenVB, requestDelegationTokenGroovy, requestDelegationTokenObjectiveC, requestDelegationTokenSwift } from 'requestExamples/authorization';
import { javascript, cChar } from 'requestExamples/codeSnippets';
export const basePostUrl = [{
  param: 'POST',
  value: '/auto/auth/nonce/1'
}];
export const clientTokenPostUrl = [{
  param: 'POST',
  value: '/auto/auth/nonce/1'
}];
export const clientTokenRequest = [{
  param: 'POST',
  value: '/auto/auth/ctoken/1'
}];
export const delegationTokenPostUrl = [{
  param: 'POST',
  value: '/auto/auth/dtoken/1'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  basePostUrl,
  clientTokenPostUrl,
  clientTokenRequest,
  delegationTokenPostUrl,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "autonomous-authentication"
    }}>{`Autonomous authentication`}</h1>
    <p>{`Using autonomous authentication, neither your RSA private key used by your client id nor the password of the User ID are sent over the wire.`}<br />{`
All requests are done over HTTPS using TLS 1.3.`}</p>
    <br />
    <br />

    <h2 {...{
      "id": "request-a-nonce"
    }}>{`Request a nonce`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			A nonce is a string generated by the server that you will need in order to form a self-signed token. You'll
			need a client id for this.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			More info on how to register a new client <a href="/api/getting-started#adding-a-new-client">here</a>.
		</p>
	</div>
	<Table title="Request nonce" rows={basePostUrl} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'client_id',
      type: 'string',
      description: 'The client ID as registered in SmartVault.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "client_id": "SampleCRMWeb"
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: requestNonceRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: requestNonceCurl
    }, {
      tabTitle: 'Java',
      tabInfo: requestNonceJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: requestNonceJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: requestNonceNode
    }, {
      tabTitle: 'Perl',
      tabInfo: requestNoncePerl
    }, {
      tabTitle: 'Python',
      tabInfo: requestNoncePython
    }, {
      tabTitle: 'PHP',
      tabInfo: requestNoncePhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: requestNonceRuby
    }, {
      tabTitle: 'Go',
      tabInfo: requestNonceGo
    }, {
      tabTitle: 'C#',
      tabInfo: requestNonceC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: requestNonceVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: requestNonceGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: requestNonceObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: requestNonceSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the nonce after the code property.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "code": "9FIkTrm85k2hxVjSqRuFSw"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the client id is not specified in the request body.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -2147467259,
        "error_text": "(1:0) error: Unexpected token EOF, expected: '{'."
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "request-a-client-token"
    }}>{`Request a client token`}</h2>
    <br />

    <Row mdxType="Row">
	<div>
		<p>
			This call is used to request a client token. This is the token you will use to authenticate each time your
			application makes a call to the API.
		</p>
	</div>
	<Table title="Request client token" rows={clientTokenRequest} mdxType="Table" />
    </Row>
    <br />
    <p>{`The client token is used for administrative tasks like adding users and delegating on behalf of users.`}<br />{`
In order to request a client token, you will need to form a self-signed token using the nonce and Client ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
// Structure to generate a self-signed token

BASE64
(
"SLF00" + Length(UTF8(Client ID)) + UTF8(Client ID) + Length(Nonce) + Nonce

SIGN(SHA256("SLF00" + Length(UTF8(ClientId)) + UTF8(ClientId) + Length(Nonce) + Nonce)
)
`}</code></pre>
    <br />
Where the reserved names used correspond to:
    <br />
    <br />
    <List inline values={[{
      title: 'BASE64',
      value: 'Base 64 encoding of string.'
    }, {
      title: 'UTF8',
      value: 'UTF 8 encoding of string.'
    }, {
      title: 'LENGTH',
      value: 'Length of string. Maximum value is 255.'
    }, {
      title: 'SIGN',
      value: 'PKI Signing operation using your private key.'
    }, {
      title: 'SHA256',
      value: 'SHA-256 Hash Algorithm applied to data.'
    }]} mdxType="List" />
    <br />
    <br />
    <p><strong parentName="p">{` Code snippets `}</strong></p>
    <hr></hr>
    <p>{`Find below some snippets that may help you build the code needed for retrieving the client token.`}</p>
    <InfoIcon mdxType="InfoIcon" /> Use of external dependencies may be being used in these code snippets. e.g. "BouncyCastle" is an OpenSource
library for the C# example.
    <br />
    <br />
    <Expander title="code snippets" mdxType="Expander">
	<Tabs items={[{
        tabTitle: 'Javascript',
        tabInfo: javascript
      }, {
        tabTitle: 'C#',
        tabInfo: cChar
      }]} mdxType="Tabs" />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'token',
      type: 'string',
      description: 'The self-signed access token that you formed using the Client ID and the nonce.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "token": "U0xGMDAMU2FtcGxlQ1JNV2ViFmNmRFh1bkhDYTBXZW5tUVhuU3BJOUHEXtJ+Je5g/igf0DtUcPmPw/5MPyxzZxzrKksa8UObxuiOOtFg38hL3cEMs67ggPwPZGwVF4WMb2Ix+7xGtfp0WPBRzkwUQMJZKGmBJ5PRFkGmX5M4vjmLriwFjYXa0xsGPArgQa2/dPW2gKt0xx1nAQbntDjH7kkbxoKxO+Rklw=="
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: requestClientTokenRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: requestClientTokenCurl
    }, {
      tabTitle: 'Java',
      tabInfo: requestClientTokenJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: requestClientTokenJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: requestClientTokenNode
    }, {
      tabTitle: 'Perl',
      tabInfo: requestClientTokenPerl
    }, {
      tabTitle: 'Python',
      tabInfo: requestClientTokenPython
    }, {
      tabTitle: 'PHP',
      tabInfo: requestClientTokenPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: requestClientTokenRuby
    }, {
      tabTitle: 'Go',
      tabInfo: requestClientTokenGo
    }, {
      tabTitle: 'C#',
      tabInfo: requestClientTokenC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: requestClientTokenVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: requestClientTokenGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: requestClientTokenObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: requestClientTokenSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the client token and its expiracy.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "token": "Q0xJMDAAAAAAAAABUYAlG0vidJTM88Jl/PfYdgK6oWRI7um8N3L/ZdWLCsTRXQ==",
        "expires": 86400
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the token built is not specified in the request body or not valid.`}</p>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "request-a-delegation-token"
    }}>{`Request a delegation token`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			The delegation token is used to perform actions on a user's behalf.
			<br />
			<br />
			<InfoIcon mdxType="InfoIcon" />
			The user must have authorized your application in order for you to be able to do this. If not authorized,
			this call will return an access denied error.
			<br /> To perform this authorization process, check <a target="_blank" href="/api/user-account/stat-user#authorized">
				here
			</a>.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			If you are using autonomous authentication, you will need to use the "Authorization" header to authenticate this
			request using the client id and the client token that you should have retrieved earlier.
			<br />
			The basic access authorization header value for this request needs to be:
			<code>clientId + ':' + clientToken</code>
			<br />
			<WarningIcon mdxType="WarningIcon" />
			For the "user_email" body parameter, you need to specify <strong>your SmartVault account email</strong>, not
			the one you used to request the delegation token (the developer account email).
		</p>
	</div>
	<Table title="Request delegation token" rows={delegationTokenPostUrl} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'user_email',
      type: 'string',
      description: 'The SmartVault account email address (not the developer one!).'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "user_email": "test@smartvault.com"
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: requestDelegationTokenRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: requestDelegationTokenCurl
    }, {
      tabTitle: 'Java',
      tabInfo: requestDelegationTokenJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: requestDelegationTokenJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: requestDelegationTokenNode
    }, {
      tabTitle: 'Perl',
      tabInfo: requestDelegationTokenPerl
    }, {
      tabTitle: 'Python',
      tabInfo: requestDelegationTokenPython
    }, {
      tabTitle: 'PHP',
      tabInfo: requestDelegationTokenPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: requestDelegationTokenRuby
    }, {
      tabTitle: 'Go',
      tabInfo: requestDelegationTokenGo
    }, {
      tabTitle: 'C#',
      tabInfo: requestDelegationTokenC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: requestDelegationTokenVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: requestDelegationTokenGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: requestDelegationTokenObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: requestDelegationTokenSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns the delegation token, its expiracy and the id related to the user used for retrieving it.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "token": "REVMMDAAAAAAAAASdQCQwerAQ0rDiBYb79v70bLOQOiI6cKBNWHmgUfNMJzZfUYM889NlX+llum66rNlA4iYtqoSL/UW0z9X6MIziVUF",
        "expires": 1209600,
        "id": "User:QVqGsFoKkU-OAx2KownMFw"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Will return an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if using the autonomous authentication and the Authorization header is not specified (access denied) or an error object if the "user_email" of the body is missing.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Header parameter missing
{
    "error": {
        "success": false,
        "error_code": -1593835519,
        "error_text": "Access Denied. Your rights may have changed; please Sign out and Sign back in to correct this problem.",
        "instance_guid": "e7defdf9-f42f-4ff6-8893-1b94b67965c8",
        "status_code": 401
    }
}

// user_email body parameter missing
{
    "error": {
        "success": false,
        "error_code": -2147467259,
        "error_text": "(1:0) error: Unexpected token EOF, expected: '{'."
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      